export const environment = {
    production: false,
    clientHost: 'https://qastaging.staftr.com/',
    apiUrl: 'https://mplace.pt-staging.com/api/',
    tokenRefreshBefore: -2,
    dateFormat: 'MM/dd/yyyy',
    usSpecificTimezoneOnly: true,
    title: "Staftr",
    firebaseConfig :{
        apiKey: "AIzaSyAr3j3zU9oZ7OYp6ITBBgnwKUoHrEVcm7M",
        authDomain: "mplace-dd342.firebaseapp.com",
        databaseURL: "https://mplace-qa.firebaseio.com",
        projectId: "mplace-dd342",
        storageBucket: "",
        messagingSenderId: "136536185534",
        logging: true
    },
    unitTestConfig: {
        defaultPassword: "Tester@1234"
    }
};
